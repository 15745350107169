/* Change this file to get your personal Porfolio */
// import rorIcon from "./assets/icons/ror.svg"
// import tsIcon from "./assets/icons/ts.svg"
// Website related settings
const settings = {
  isSplash: true, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Torchi's Portfolio",
  description:
    "I am a full-stack software engineer with seven years of experience, specializing in React for frontend development. I excel at creating scalable, user-friendly web applications and am passionate about leveraging cutting-edge technologies to solve complex problems and enhance user experiences.",
  og: {
    title: "Torchi RokayaPortfolio",
    type: "website",
    url: "http://torchi.com.np/",
  },
};

//Home Page
const greeting = {
  title: "Torchi Rokaya",
  logo_name: "TorchiRokaya",
  nickname: "dorje",
  subTitle:
    "Hi, I am a full-stack software engineer with seven years of experience, specializing in React for frontend development. I excel at creating scalable web applications and am passionate about leveraging cutting-edge technologies to enhance user experiences.",
  resumeLink:
    "https://drive.google.com/file/d/15J4tHIjwxb1OIGfecJSS7Lt-S8-x0mst/view?usp=drive_link",
  // portfolio_repository: "https://github.com/ashutosh1919/masterPortfolio",
  githubProfile: "https://github.com/dorje064",
};

const socialMediaLinks = [
  {
    name: "Github",
    link: "https://github.com/dorje064",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/torchi/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Gmail",
    link: "mailto:ku.dorjee@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  // {
  //   name: "X-Twitter",
  //   link: "https://twitter.com/ashutosh_1919",
  //   fontAwesomeIcon: "fa-x-twitter", // Reference https://fontawesome.com/icons/x-twitter?f=brands&s=solid
  //   backgroundColor: "#000000", // Reference https://simpleicons.org/?q=x
  // },
  {
    name: "Facebook",
    link: "https://www.facebook.com/kunsang.dorjee",
    fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/dorje64/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
];

const skills = {
  data: [
    {
      title: "FrontEnd Development",
      // fileName: "FullStackImg",
      animationData: require("./assets/animations/be.json"),
      animationStyle: { width: 300, height: 300 },
      skills: [
        "⚡ Building responsive and dynamic web interfaces using React and Redux",
        "⚡ Optimizing web applications with React-Query for efficient data fetching and state management",
        "⚡ Collaborating with backend teams to integrate RESTful APIs and ensure seamless user experience",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "TypeScript",
          imageSrc:
            "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/typescript/typescript-original.svg",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Yarn",
          fontAwesomeClassname: "simple-icons:yarn",
          style: {
            color: "#2C8EBB",
          },
        },
      ],
    },
    {
      title: "Backend Development",
      animationData: require("./assets/animations/apiserver.json"),
      animationStyle: { width: 220, height: 220 },
      skills: [
        "⚡ Designing and implementing scalable and secure backend systems using Ruby on Rails",
        "⚡ Developing RESTful APIs and microservices with Node.js and TypeScript for efficient data handling",
        "⚡ Integrating databases, such as PostgreSQL and MongoDB, to manage and optimize data storage and retrieval",
      ],
      softwareSkills: [
        {
          skillName: "Ruby on Rails",
          // fontAwesomeClassname: "simple-icons:googlecloud",
          imageSrc:
            "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/rails/rails-original-wordmark.svg",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "Ruby",
          // fontAwesomeClassname: "simple-icons:googlecloud",
          imageSrc:
            "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/ruby/ruby-original.svg",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "devicon-plain:nodejs-wordmark",
          style: {
            color: "#339933",
          },
        },

        {
          skillName: "ExpressJs",
          imageSrc:
            "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/express/express-original.svg",
          style: {
            color: "#339933",
          },
        },

        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "MySql",
          imageSrc:
            "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/mysql/mysql-original-wordmark.svg",
          style: {
            color: "#1488C6",
          },
        },
      ],
    },
    {
      title: "Automation Testing",
      animationData: require("./assets/animations/testcase.json"),
      animationStyle: { width: 300, height: 300 },
      skills: [
        "⚡ Developing end-to-end automated test scripts for web applications using Cypress to ensure high-quality user experiences",
        // "⚡ Integrating Cypress with CI/CD pipelines to enable continuous testing and rapid feedback",
        "⚡ Performing API testing with Cypress to validate data integrity and backend functionality",
      ],
      softwareSkills: [
        {
          skillName: "Cypress",
          imageSrc:
            "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/cypressio/cypressio-original.svg",
          style: {
            color: "#FF2BC2",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    // {
    //   siteName: "LeetCode",
    //   iconifyClassname: "simple-icons:leetcode",
    //   style: {
    //     color: "#F79F1B",
    //   },
    //   profileLink: "https://leetcode.com/layman_brother/",
    // },
    // {
    //   siteName: "HackerRank",
    //   iconifyClassname: "simple-icons:hackerrank",
    //   style: {
    //     color: "#2EC866",
    //   },
    //   profileLink: "https://www.hackerrank.com/layman_brother",
    // },
    // {
    //   siteName: "Codechef",
    //   iconifyClassname: "simple-icons:codechef",
    //   style: {
    //     color: "#5B4638",
    //   },
    //   profileLink: "https://www.codechef.com/users/ashutosh_1919",
    // },
    // {
    //   siteName: "Codeforces",
    //   iconifyClassname: "simple-icons:codeforces",
    //   style: {
    //     color: "#1F8ACB",
    //   },
    //   profileLink: "http://codeforces.com/profile/layman_brother",
    // },
    // {
    //   siteName: "Hackerearth",
    //   iconifyClassname: "simple-icons:hackerearth",
    //   style: {
    //     color: "#323754",
    //   },
    //   profileLink: "https://www.hackerearth.com/@ashutosh391",
    // },
    // {
    //   siteName: "Kaggle",
    //   iconifyClassname: "simple-icons:kaggle",
    //   style: {
    //     color: "#20BEFF",
    //   },
    //   profileLink: "https://www.kaggle.com/laymanbrother",
    // },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Kathmandu University School of Engineering",
      subtitle: "B.E. in Computer Engineering",
      logo_path: "kulogo.png",
      alt_name: "KU CE",
      duration: "2011 - 2016",
      descriptions: [
        "⚡ I have studied core computer engineering subjects like Data Structures, Algorithms, Operating Systems, and Database Management Systems.",
        "⚡ Additionally, I have completed courses in Network Security, Embedded Systems, Machine Learning, and Software Development.",
      ],
      website_link: "https://ku.edu.np/",
    },
    {
      title: "Xavier International Collage",
      subtitle: "+2 Science",
      logo_path: "xavier_logo.png",
      alt_name: "Xavier",
      duration: "2009 - 2011",
      descriptions: [
        "⚡ Our two-year course focuses on understanding core concepts in biology, chemistry, math, and physics.",
        "⚡ We enhance learning by incorporating practical applications of the scientific theories taught in class.",
      ],
      website_link: "https://plus.xavier.edu.np",
    },
  ],
};

const certifications = { certifications: [] };
// const certifications = {
//   certifications: [
//     {
//       title: "Machine Learning",
//       subtitle: "- Andrew Ng",
//       logo_path: "stanford_logo.png",
//       certificate_link:
//         "https://www.coursera.org/account/accomplishments/verify/22MTSSC5WDTM",
//       alt_name: "Stanford University",
//       color_code: "#8C151599",
//     },
//     {
//       title: "Deep Learning",
//       subtitle: "- Andrew Ng",
//       logo_path: "deeplearning_ai_logo.png",
//       certificate_link:
//         "https://www.coursera.org/account/accomplishments/specialization/H8CPSFXAJD2G",
//       alt_name: "deeplearning.ai",
//       color_code: "#00000099",
//     },
//     {
//       title: "ML on GCP",
//       subtitle: "- GCP Training",
//       logo_path: "google_logo.png",
//       certificate_link:
//         "https://www.coursera.org/account/accomplishments/specialization/EB4VJARK8647",
//       alt_name: "Google",
//       color_code: "#0C9D5899",
//     },
//     {
//       title: "Data Science",
//       subtitle: "- Alex Aklson",
//       logo_path: "ibm_logo.png",
//       certificate_link:
//         "https://www.coursera.org/account/accomplishments/specialization/PLEAPCSJBZT5",
//       alt_name: "IBM",
//       color_code: "#1F70C199",
//     },
//     {
//       title: "Big Data",
//       subtitle: "- Kim Akers",
//       logo_path: "microsoft_logo.png",
//       certificate_link:
//         "https://drive.google.com/file/d/164zKCFOsI4vGqokc-Qj-e_D00kLDHIrG/view",
//       alt_name: "Microsoft",
//       color_code: "#D83B0199",
//     },
//     {
//       title: "Advanced Data Science",
//       subtitle: "- Romeo Kienzler",
//       logo_path: "ibm_logo.png",
//       certificate_link:
//         "https://www.coursera.org/account/accomplishments/verify/BH2T9BRU87BH",
//       alt_name: "IBM",
//       color_code: "#1F70C199",
//     },
//     {
//       title: "Advanced ML on GCP",
//       subtitle: "- GCP Training",
//       logo_path: "google_logo.png",
//       certificate_link:
//         "https://www.coursera.org/account/accomplishments/verify/5JZZM7TNQ2AV",
//       alt_name: "Google",
//       color_code: "#0C9D5899",
//     },
//     {
//       title: "DL on Tensorflow",
//       subtitle: "- Laurence Moroney",
//       logo_path: "deeplearning_ai_logo.png",
//       certificate_link:
//         "https://www.coursera.org/account/accomplishments/verify/6T4DCUGNK8J8",
//       alt_name: "deeplearning.ai",
//       color_code: "#00000099",
//     },
//     {
//       title: "Fullstack Development",
//       subtitle: "- Jogesh Muppala",
//       logo_path: "coursera_logo.png",
//       certificate_link:
//         "https://www.coursera.org/account/accomplishments/certificate/NRANJA66Y2YA",
//       alt_name: "Coursera",
//       color_code: "#2A73CC",
//     },
//     {
//       title: "Kuberenetes on GCP",
//       subtitle: "- Qwiklabs",
//       logo_path: "gcp_logo.png",
//       certificate_link:
//         "https://google.qwiklabs.com/public_profiles/e4d5a92b-faf6-4679-a70b-a9047c0cd750",
//       alt_name: "GCP",
//       color_code: "#4285F499",
//     },
//     {
//       title: "Cryptography",
//       subtitle: "- Saurabh Mukhopadhyay",
//       logo_path: "nptel_logo.png",
//       certificate_link:
//         "https://drive.google.com/open?id=1z5ExD_QJVdU0slLkp8CBqSF3-C3g-ro_",
//       alt_name: "NPTEL",
//       color_code: "#FFBB0099",
//     },
//     {
//       title: "Cloud Architecture",
//       subtitle: "- Qwiklabs",
//       logo_path: "gcp_logo.png",
//       certificate_link:
//         "https://google.qwiklabs.com/public_profiles/5fab4b2d-be6f-408c-8dcb-6d3b58ecb4a2",
//       alt_name: "GCP",
//       color_code: "#4285F499",
//     },
//   ],
// };

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Freelancing",
  description:
    "With over seven years in IT, I’ve worked as a frontend lead, full-stack developer, and React specialist. My experience spans architecture, testing, dynamic web development, and backend work with PHP, Ruby on Rails, and Node.js. I've utilized technologies like TypeScript, Gatsby, GraphQL, Contentful, and React Query, managing diverse projects from large-scale applications to part-time freelancing.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Sr. Software Engineer",
          company: "MySecond Teacher",
          company_url: "https://www.mysecondteacher.com/",
          logo_path: "mst_log.png",
          duration: "Nov 2020 - Present",
          location: "Kathmandu, Bagmati, Nepal",
          description:
            "As a Frontend Lead, I lead multiple projects, design scalable architectures, and introduce automation testing to enhance code quality. I also drive the adoption of modern technologies like TypeScript, NX, Storybook, React-query etc, ensuring best practices and consistency in UI components across teams.",
          color: "#000000",
        },
        {
          title: "Software Engineer",
          company: "Codavatar Tech Pvt. Ltd.",
          company_url: "http://www.codavatar.com",
          logo_path: "codavatar_logo.png",
          duration: "Oct 2020- Nov 2020",
          location: "Kathmandu, Bagmati, Nepal",
          description:
            "I worked as a frontend developer for two months, where I focused on building user interfaces and integrating with APIs to create seamless and responsive web applications.",
          color: "#0879bf",
        },
        {
          title: "Software Engineer",
          company: "Insight Workshop",
          company_url: "https://insightworkshop.io",
          logo_path: "iw_logo.png",
          duration: "Jul 2018 - Jun 2020",
          location: "Kathmadu, Bagmati, Nepal",
          description:
            "I worked as a mid-level full-stack developer, where I single-handedly managed multiple client projects simultaneously (more than three at a time). I utilized a range of technologies including React, Node.js, Vue.js, Ruby on Rails (RoR), and Angular to deliver robust and scalable solutions.",
          color: "#9b1578",
        },
        {
          title: "Associated Software Engineer",
          company: "Enlive Information Technology",
          company_url: "https://www.enlivtechnology.com/",
          logo_path: "enliv_logo.png",
          duration: "Dec 2017 - Apr 2018",
          location: "Lalitpur, Bagmati, Nepal",
          description:
            "As a full-stack web developer, I worked with Ruby on Rails, React, and ElasticSearch. I led development efforts on projects like NightShift and DayOff, applying my expertise in these technologies. Additionally, I guided interns as an instructor, sharing knowledge and ensuring the successful execution of project goals.",
          color: "#9b1578",
        },
        {
          title: "Associated Software Developer",
          company: "Olive Media",
          company_url: "https://www.linkedin.com/company/olive-media_2",
          logo_path: "olive_logo.png",
          duration: "Oct 2016 - Dec 2016",
          location: "Kathmandu, Bagmati, Nepal",
          description:
            "As a junior developer, I worked with PHP frameworks and jQuery, focusing on learning about various PHP frameworks and building components. My responsibilities included bug fixing and contributing to the development of robust, functional web components.",
          color: "#fc1f20",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "RoR/JS Intern",
          company: "Cloud Factory",
          company_url: "https://www.cloudfactory.com.np/",
          logo_path: "cf_logo.png",
          duration: "June 2016 - Aug 2016",
          location: "Lalitpur, Bagmati, ",
          description:
            "I began my career as an intern, where I developed a strong foundation in Ruby on Rails and JavaScript. During this time, I created workflows, instructions, specifications, and data transformation processes for multiple projects. I also gained valuable experience working on the CloudFactory platform, which further fueled my passion for software development.",
          color: "#000000",
        },
      ],
    },
    {
      title: "Freelance",
      experiences: [
        {
          title: "Front Developer React",
          company: "IdenTv",
          company_url: "https://www.identv.com/ ",
          logo_path: "identv_logo.jpeg",
          duration: "May, 2020 - Aug,2020",
          location: "Remote",
          description:
            "As a React developer, I worked with Gatsby for static site generation, GraphQL for data querying, and Contentful for content management. This role involved creating high-performance, dynamic web applications using these modern frontend technologies.",
          color: "#4285F4",
          // "Frontend Developer(React)
          // IdenTv May, 2020 - Aug,2020
          // Stack: React
          // + Worked as a react developer
          // + worked in gatsby and contentful
          // + Projects: Asharq, Juicer
        },
        {
          title: "RoR and React developer",
          company: "Upwork",
          company_url: "upwork.com",
          logo_path: "upwork.svg",
          duration: "Around 400+ hours",
          location: "Remote",
          description:
            "In my free time, I worked on multiple part-time freelancing projects, which allowed me to apply my skills across various domains and further enhance my development experience..",
          color: "#4285F4",
        },
        {
          title: "Associate RoR Developer",
          company: "With Relay",
          company_url: "https://www.withrelay.com/",
          logo_path: "withrelay_logo.png",
          duration: "Jan 2017 - Sep 2017",
          location: "Remote, Canada",
          description:
            "As a Full Stack Developer, I primarily focused on backend development with Ruby on Rails. I implemented SMS and MMS features using Twilio, developed dynamic user segmentation, and integrated Stripe Connect API for managed accounts. I collaborated with remote teams and worked on projects like Relay, utilizing RoR, jQuery, and Stripe API.",
          color: "#D83B01",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create Data Science projects and deploy them to web applications using cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description: "Some of my published Articles, Blogs and Research.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    {
      id: "neuro-symbolic-sudoku-solver",
      name: "Neuro-Symbolic Sudoku Solver",
      createdAt: "2023-07-02T00:00:00Z",
      description: "Paper published in KDD KiML 2023",
      url: "https://arxiv.org/abs/2307.00653",
    },
    {
      id: "mdp-diffusion",
      name: "MDP-Diffusion",
      createdAt: "2023-09-19T00:00:00Z",
      description: "Blog published in Paperspace",
      url: "https://blog.paperspace.com/mdp-diffusion/",
    },
    {
      id: "consistency-models",
      name: "Consistency Models",
      createdAt: "2023-10-12T00:00:00Z",
      description: "Blog published in Paperspace",
      url: "https://blog.paperspace.com/consistency-models/",
    },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "portfolio.png",
    description:
      "I am available on almost every social media. You can message me, I will reply within 24 hours.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I like to document some of my experiences in professional career journey as well as some technical knowledge sharing.",
    link: "https://railslab.wordpress.com/",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle: "Kathmandu, Bagmati, Nepal",
    locality: "Boudha",
    country: "Nepal",
    region: "Bagmati",
    postalCode: "4440",
    streetAddress: "Baudha",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://maps.app.goo.gl/ApioRUUs6tcR1m9K8",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
