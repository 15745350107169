import React from "react";
import "./LoaderLogo.css";

const LogoLoader = () => {
  return (
    <div>
      <img
        src={require("../../assets/images/computer_logo.png")}
        alt="screen"
      />
      <div className="splash">
        <span className="animation-text"></span>
      </div>
    </div>
  );
};

export default LogoLoader;
